import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { HubDTO } from '../../../dto';
import { SendHubCommand, UpdateHub } from '../../../apiService';
import React, { useState } from 'react';
import { IsOnline } from '../../TimeUtils/TimeUtils';
import { SubmitFiles } from '../SubmitFiles';
import { UploadConfig } from '../UploadConfig';
import { PopoverComponent } from './Popover';
import { iquColor } from '../../../styles';
import { IoMdHelpCircle } from 'react-icons/io';
import { t } from 'i18next';
import { UpdateMenuModal } from '../../update/UpdateMenuModal';

interface HubBoxCardInterface {
  hub: HubDTO;
}

function useSubmitCommandAlert() {
  const disclosure = useDisclosure();
  const cancelRef = React.useRef(null);
  const [title, setTitle] = useState('');
  const [onSubmitCallback, setOnSubmitCallback] = useState(() => () => {});

  return {
    openSubmitCommandAlert: (title: string, onSubmitCallback: () => void) => {
      setTitle(title);
      setOnSubmitCallback(() => onSubmitCallback);
      disclosure.onOpen();
    },
    SubmitCommandAlert: () => (
      <AlertDialog isOpen={disclosure.isOpen} leastDestructiveRef={cancelRef} onClose={disclosure.onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>
            <AlertDialogBody>{t('are_you_sure')}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={disclosure.onClose}>
                {t('cancel')}
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  disclosure.onClose();
                  onSubmitCallback();
                }}
                ml={3}
              >
                Submit
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    ),
  };
}

export function HubInfoCard({ hub }: HubBoxCardInterface) {
  const toast = useToast();
  const { openSubmitCommandAlert, SubmitCommandAlert } = useSubmitCommandAlert();

  const sendCommand = (command: string, commandName: string) => {
    SendHubCommand(hub.hub_id, command).then(() => {
      toast({
        title: commandName + ' ' + t('is_applied_lowercase'),
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    });
  };
  const handleAuthentication = () => {
    if (!hub.is_authenticated) {
      UpdateHub(hub.hub_id, { is_authenticated: true });
    }
  };
  // const handleClick = (_command: string) => {
  //   const commandName = _command.split('?')[0];
  //   openSubmitCommandAlert(commandName, sendCommand);
  // };
  return (
    <Card width={'100%'}>
      <SubmitCommandAlert />
      <CardHeader>
        <Center>
          <VStack>
            <Heading as={'h1'}>{t('info_uppercase')}</Heading>
            <Text size={'s'}>{IsOnline(hub) ? t('online') : t('offline')}</Text>
          </VStack>
        </Center>
      </CardHeader>
      <Divider paddingTop={'2px'} paddingBottom={'2px'} />
      <CardBody>
        <Text>
          {t('name')}: {hub.hub_name}
        </Text>
        <Text>
          {t('ip')}: {hub.ipaddress}
        </Text>
        <Text>
          {t('address')}: {hub.address}, {hub.post_code}, {hub.city}
        </Text>
        {hub.is_authenticated ? (
          <Text>
            {t('authenticated')}: {t('yes')}
          </Text>
        ) : (
          <Button onClick={handleAuthentication}>{t('authenticate')}</Button>
        )}
        <Divider paddingTop={'10px'} paddingBottom={'10px'} />
        <Flex w={'100%'} justifyContent={'center'} mt={4} gap={4}>
          <Heading>{t('add_task_uppercase')}</Heading>
          <PopoverComponent
            PopoverBodyText={t('add_task_info')}
            PopoverHeaderText={t('add_task')}
            PopoverTriggerComponent={
              <IconButton
                aria-label={'Add task info'}
                icon={<IoMdHelpCircle size={'32px'} />}
                bg={'none'}
                color={iquColor}
              />
            }
          />
        </Flex>
        <SimpleGrid minChildWidth="40%" spacing="20px" paddingTop={'20px'}>
          <Button
            value={'restart'}
            onClick={() => {
              openSubmitCommandAlert(t('restart'), () => sendCommand('restart', t('restart')));
            }}
          >
            {t('restart')}
          </Button>
          <Button
            onClick={() => {
              openSubmitCommandAlert(t('reboot'), () => sendCommand('reboot', t('reboot')));
            }}
            value={'reboot'}
          >
            {t('reboot')}
          </Button>
          <Button
            onClick={() => {
              openSubmitCommandAlert(t('start'), () => sendCommand('start', t('start')));
            }}
            value={'start'}
          >
            {t('start')}
          </Button>
          <Button
            onClick={() => {
              openSubmitCommandAlert(t('stop'), () => sendCommand('stop', t('stop')));
            }}
            value={'stop'}
          >
            {t('stop')}
          </Button>
          <UpdateMenuModal
            hub_id={hub.hub_id}
            openSubmitCommandAlert={openSubmitCommandAlert}
            sendCommand={sendCommand}
          />
          <SubmitFiles
            hubId={hub.hub_id}
            directoryHelp={'configs/'}
            openSubmitCommandAlert={openSubmitCommandAlert}
            sendCommand={sendCommand}
          />
          <UploadConfig hub_id={hub.hub_id} openSubmitCommandAlert={openSubmitCommandAlert} sendCommand={sendCommand} />
          <Button
            onClick={() => {
              openSubmitCommandAlert(t('self_destruct'), () => sendCommand('destruct', t('self_destruct')));
            }}
            value={'destruct'}
          >
            {t('self_destruct')}
          </Button>
        </SimpleGrid>
        <Divider paddingTop={'10px'} paddingBottom={'10px'} />
      </CardBody>
    </Card>
  );
}
